import axios from "axios";

const GetRequest=async(url)=>{

    let sendResponse;

    await axios.get(url).then(
        (response)=>{
            // console.log("response ",response.data);
            sendResponse=response.data;
        },
        (error)=>{
            // console.log("error ",error);
            let response={result: '0', response:'3'};
            sendResponse=response;
        }
    )
    return sendResponse;
}
export default GetRequest;