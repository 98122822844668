import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Routing from './Routes/Routing';

function App() {
  return (
    <>
      <Routing/>
    </>
  );
}
export default App;