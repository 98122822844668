import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { redirectApi } from "../Data/Api";
import GetRequest from "../Request/GetRequest";
import { gameIdUniversal } from "../Data/Api";
import "../Css/Pending.css";

const Redirect=()=>{

    const navigate=useNavigate();

    //to get cli on load
    useEffect(()=>{
        getCli();
        // eslint-disable-next-line
    },[]);

    //Method to get client (cli) from requestParam
    const getCli=()=>{
        let params=new URLSearchParams(window.location.search);
        let cli=params.get("cli");
        let sid=params.get("sid");
        // console.log("cli is ",cli);
        sendOnBackend(cli,gameIdUniversal,sid);
    }

    //Method to hit on backend
    const sendOnBackend=(cli,gameId,sid)=>{
        let promise=GetRequest(`${redirectApi}?cli=${cli}&gameId=${gameId}&sid=${sid}`);
        promise.then(e=>{
            handleResponse(e);
        })
    }

    //Method to handle response
    const handleResponse=(e)=>{
        // console.log("e ",e);

        if(e.response==='3')
        {
            //error
            navigate("/error");
        }
        else if(e.response==='1')
        {
            if(e.result==='1')
            {
                //Status:Active
                window.location.href=e.link;
            }
            else if(e.result==='0')
            {
                //Status:Inactive
                navigate("/billingPending");
            }
            else
            {
                //Status:Inactive
                navigate("/billingPending");
            }
        }
        else if(e.response==='0')
        {
            navigate("/error");
        }
        else
        {
            navigate("/error");
        }
    }

    return(
        <>
             <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        {/* <h1></h1> */}
                        <h2>Please Wait</h2>
                    </div>
                </div>
            </div>   
        </>
    );
}
export default Redirect;