import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BillingPending from "../Pages/BillingPending";
import ErrorPage from "../Pages/ErrorPage";
import PageNotFound from "../Pages/PageNotFound";
import Redirect from "../Pages/Redirect";
import Terms from "../Pages/Terms";

const Routing=()=>{
    return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Terms/>} exact={true}></Route>
                    <Route path="/Redirect" element={<Redirect/>} exact={true}></Route>
                    <Route path="/terms" element={<Terms/>} exact={true}></Route>
                    <Route path="/billingPending" element={<BillingPending/>} exact={true}></Route>
                    <Route path="*" element={<PageNotFound/>} exact={true}></Route>
                    <Route path="/error" element={<ErrorPage/>} exact={true}></Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}
export default Routing;