import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { getTermsApi } from "../Data/Api";
import GetRequest from "../Request/GetRequest";
import { toast, ToastContainer } from "react-toastify";
import { gameIdUniversal } from "../Data/Api";

const Terms=()=>{

    const navigate=useNavigate();

    useEffect(()=>{
        getTerms();
        // eslint-disable-next-line
    },[]);

    //Method to get Terms from backend
    const getTerms=()=>{
        let promise=GetRequest(`${getTermsApi}?gameId=${gameIdUniversal}`);
        promise.then(e=>{
            handleResponse(e);
        })
    }

    const[terms,setTerms]=useState('');

    //Method to handle response
    const handleResponse=(e)=>{
        // console.log("e ",e);

        if(e.response==='3')
        {
            navigate("/error");
        }
        else if(e.response==='0')
        {
            toast.error("Something Went Wrong");
        }
        else if(e.response==='1')
        {
            setTerms(e.terms);
        }
        else
        {
            toast.error("Something Went Wrong");
        }
    }

    return(
        <>
            <ToastContainer/>
            <div className="container">
                {parse(terms)}
          </div>
        </>
    );
}
export default Terms;